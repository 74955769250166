<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="mb-2 me-sm-2">
    <button
      class="btn btn-primary btn-lg w-100"
      (click)="openAddEditIncidentTypeComponent()"
    >
      + Add Incident Type
    </button>
  </div>
  <div class="flex-sm-grow-1" *ngIf="initialList?.length > 5">
    <gtapp-auto-complete
      [data]="incidentTypeDropDownList"
      placeHolder="Search Incident Types"
      searchKeyword="name"
      [itemTemplate]="incidentTypeTemplate"
      (inputChanged)="onIncidentTypeLookup($event)"
      (selected)="openAddEditIncidentTypeComponent($event)"
      (submitted)="filterResults($event)"
      [enableSearchCloseOption]="true"
      (fieldCleared)="filterResults('')"
    >
    </gtapp-auto-complete>
    <ng-template #incidentTypeTemplate let-item>
      <div>{{ item?.name }}</div>
    </ng-template>
  </div>
</div>

<ng-container *ngIf="initialList?.length > 0">
  <gtapp-card-list
    [mobileCardUIData]="incidentTypeList"
    [mobileCardDetails]="cardDetails"
    [otherParams]="{ paginationData: true }"
    [mobileActionIcons]="cardActionIcons"
    (actionClick)="deleteIncidentType($event?.event)"
    (rowClick)="openAddEditIncidentTypeComponent($event)"
  ></gtapp-card-list>
</ng-container>
