<div
  class="row row-cols-1 row-cols-lg-2 d-flex"
  [ngStyle]="{ height: containerHeight }"
>
  <ng-container *ngIf="!selectedUser?.id || largeView">
    <div class="col col-lg-4 d-flex flex-column">
      <div class="top-section">
        <div
          *ngFor="let user of userMessageList"
          (click)="getUserMessage(user); user.unread = 0"
        >
          <div class="d-flex gt-card mb-3 cursor-pointer">
            <div class="flex-grow-1">
              {{ user?.full_name }}
            </div>
            <div *ngIf="user?.unread" class="badge bg-danger">
              {{ user?.unread }}
            </div>
          </div>
        </div>
        <div class="mt-1 mx-1"></div>
      </div>

      <button
        class="btn btn-lg w-100 btn-primary rounded-0"
        (click)="openTemplate(guardTemplate)"
      >
        + New Chat
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedUser?.id">
    <div class="col col-lg-8 d-flex flex-column">
      <div
        #content
        class="top-section fixed-message-height"
        [ngStyle]="{ 'max-height': messageFixedHeight }"
      >
        <div class="fw-bold text-center">{{ selectedUser?.full_name }}</div>
        <div
          #messages
          *ngFor="let message of messageList"
          class="mb-2 d-flex"
          [ngClass]="message?.sender?.id == subscriberUserId ? '' : 'receiver'"
        >
          <div class="w-75 message-card">
            <div
              *ngIf="message?.hand_over_log"
              class="small-font alert alert-info p-2 m-0 mb-1"
            >
              <i class="fa-solid fa-reply me-2"></i>
              <span class="fw-semibold">{{ message?.hand_over_log_desc }}</span>
            </div>
            <div
              class="small-font float-end text-secondary"
              *ngIf="
                message?.sender?.id == subscriberUserId && message?.read_at
              "
            >
              Read at: {{ message?.read_at | customDate : "d MMM y HH:mm" }}
            </div>
            <div class="small-font text-secondary">
              {{ message?.created_at | customDate : "d MMM y HH:mm" }}
              <span *ngIf="message?.event_key">
                | {{ message?.event_key }}</span
              >
            </div>

            <div>
              {{ message?.message }}
            </div>
            <img
              (click)="onOpenImage($event, message)"
              *ngIf="message?.file"
              src="{{ message?.file }}"
              height="120"
              width="120"
            />

            <ng-container *ngIf="message?.reply?.id">
              <div class="small-font border border-dark rounded-2 px-2 mt-3">
                <div class="text-secondary">
                  <i class="fa-solid fa-reply me-2"></i
                  >{{ message?.reply?.sender?.full_name }}
                  |
                  {{
                    message?.reply?.created_at | customDate : "d MMM y HH:mm"
                  }}
                </div>
                <div>
                  {{ message?.reply?.message }}
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="align-items-center d-flex"
            [ngClass]="
              message?.sender?.id == subscriberUserId ? '' : 'rotate-y'
            "
            (click)="selectedMessage = message"
          >
            <span class="mx-2"><i class="fa-solid fa-reply fa-2x"></i></span>
          </div>
        </div>
      </div>

      <div id="bottom-btns">
        <ng-container *ngIf="selectedMessage">
          <div class="small-font alert alert-info p-2 m-0 mb-1">
            <i class="fa-solid fa-reply me-2"></i>
            <span>{{ selectedMessage?.message }}</span>
            <span class="float-end" (click)="selectedMessage = null"
              ><i class="fa-solid fa-x"></i
            ></span>
          </div>
        </ng-container>
        <ng-container *ngIf="handOverLogData?.id">
          <div class="small-font alert alert-info p-2 m-0 mb-1">
            <i class="fa-solid fa-reply me-2"></i>
            <span class="fw-semibold">{{ handOverLogData?.description }}</span>
            <span class="float-end" (click)="handOverLogData = null"
              ><i class="fa-solid fa-x"></i
            ></span>
          </div>
        </ng-container>

        <div class="d-flex">
          <div class="flex-grow-1">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="chatMessage"
                placeholder="Message"
                (focus)="hideAdditionalItems = true; showFabActions = false"
                (blur)="hideAdditionalItems = false"
                (keyup.enter)="chatMessage ? sendMessage() : null"
              />
              <label>Message</label>
            </div>
          </div>
          <ng-container *ngIf="!hideAdditionalItems">
            @if(showFabActions){
            <ng-container>
              <div class="ms-1">
                <button
                  class="btn btn-outline-primary btn-lg"
                  (click)="openJobSearchTemplate(jobSearchTemplate)"
                >
                  <i class="fa-solid fa-briefcase fa-2x"></i>
                </button>
              </div>
              <div class="ms-1">
                <button
                  class="btn btn-outline-primary btn-lg"
                  (click)="openCamPopupDialog()"
                >
                  <i class="fa-solid fa-file fa-2x"></i>
                </button>
              </div>
            </ng-container>
            }@else {
            <ng-container>
              <div class="ms-1">
                <button
                  class="btn btn-outline-primary btn-lg"
                  (click)="showHideFAB()"
                >
                  <i class="fa-solid fa-plus fa-2x"></i>
                </button>
              </div>
            </ng-container>
            }
          </ng-container>

          <div class="ms-1" *ngIf="!showFabActions">
            <button
              class="btn btn-outline-primary btn-lg"
              [disabled]="!chatMessage"
              (click)="sendMessage()"
            >
              <i class="fa fa-paper-plane fa-2x"></i>
            </button>
          </div>
        </div>
        <div class="pt-2" *ngIf="!largeView">
          <button
            type="button"
            class="btn btn-lg btn-dark w-100"
            (click)="goBack()"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #guardTemplate let-data let-guardTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Select Guard
      <span class="float-end" (click)="guardTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="mb-2">
      <gtapp-auto-complete
        [data]="assigneeSearchList"
        placeHolder="Search Guards"
        searchKeyword="full_name"
        [itemTemplate]="guardTemplate"
        (inputChanged)="searchAssignee($event)"
      >
      </gtapp-auto-complete>
      <ng-template #guardTemplate let-item>
        <div>{{ item?.full_name }}</div>
      </ng-template>
    </div>
    <table class="table">
      <tbody>
        <tr *ngFor="let assignee of assigneeList" class="pointer">
          <td
            class="cursor-pointer"
            (click)="
              getUserMessage(assignee);
              guardTemplate.close();
              newChat = true;
              messageList = []
            "
          >
            {{ assignee?.full_name }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #jobSearchTemplate let-jobSearchTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Search Jobs
      <span class="float-end" (click)="jobSearchTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="input-group my-2">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            placeholder="Filter Jobs"
            (input)="searchJobs($event)"
            [(ngModel)]="jobFilterValue"
          />
          <label>Filter Checkpoints</label>
        </div>

        <span
          class="input-group-text"
          *ngIf="jobFilterValue"
          (click)="searchJobs({ target: { value: '' } })"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div class="overflow-assignee row row-cols-1 row-cols-lg-2">
        <gtapp-card-list
          [mobileCardUIData]="filteredJobList"
          [mobileCardDetails]="jobCardsDetails"
        ></gtapp-card-list>
      </div>
    </div>
  </div>
</ng-template>
