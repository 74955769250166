import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../../environments/environment';
import { isLargeScreen } from '../../../../../global.variable';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DynamicScriptLoadService } from '../../../../shared/services/dynamic-script-load.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { SubscriptionService } from '../subscription.service';
declare var Square: any;
@Component({
  selector: 'gtapp-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrl: './saved-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedCardsComponent implements OnInit, OnDestroy {
  @Output() selectCard = new EventEmitter();
  @Output() getSavedCards = new EventEmitter();
  @Input() savedCards: any = [];

  showSqForm: boolean = false;
  cardHolderName: any;
  isPWAApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );
  dialogRef: any;
  newCard: any;
  largeView: Boolean = isLargeScreen;
  selectedCard: any;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private subscriptionService: SubscriptionService,
    private scriptLoader: DynamicScriptLoadService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    private toasterService: ToasterService
  ) {}
  ngOnDestroy(): void {
    this.showSqForm = false;
  }
  deleteCard(card: any) {
    let dialogMsg = 'Are you sure you want to remove this payment card?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete Card`,
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.subscriptionService
          .deleteSavedCard(card.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
              this.getSavedCards.emit(true);
            } else {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }

  addNewCard(template: TemplateRef<any>) {
    const squareScript =
      environment.production === true && environment.devMode === false
        ? 'square-script-prod'
        : 'square-script';
    this.spinnerService.show();
    this.scriptLoader
      .load(squareScript)
      .then((data) => {
        setTimeout(() => {
          this.addCard();
          this.dialogRef = this.dialogService.open(
            template,
            {
              data: {},
            },
            this.viewContainerRef
          );
        }, 1000);
      })
      .catch((error) => console.error(error));
  }

  ngOnInit(): void {}

  async addCard() {
    this.subscriptionService
      .getPaymentProviderConfigs()
      .subscribe(async (response: any) => {
        if (response['status'] == 'success') {
          var cardPreData = {};
          this.ngZone.run(() => {
            // Show spinner immediately
            this.showSqForm = true;
          });
          cardPreData = {
            postalCode: '123456',
          };
          const paymentProcessorConfig = response['data'];
          const payments = Square.payments(
            paymentProcessorConfig.app_id,
            paymentProcessorConfig.location_id
          );
          const card = await payments.card(cardPreData);
          setTimeout(() => {
            card.attach('#card-container');
            this.spinnerService.hide();
            this.newCard = card;
          }, 100);
        }
      });
  }
  tokenizeAddNewCard() {
    this.spinnerService.show();
    try {
      this.newCard
        ?.tokenize()
        .then((result: any) => {
          if (result.token) {
            this.subscriptionService
              .addNewPaymnetCard({
                source: result.token,
                card_holder_name: this.cardHolderName,
                idempotency_key: uuidv4(),
              })
              .subscribe(async (response: any) => {
                if (response?.status == 'success') {
                  this.spinnerService.hide();
                  this.toasterService.setMessage({
                    successMessage: response?.message,
                    errorMessage: '',
                  });
                  this.dialogRef.close();
                  this.getSavedCards.emit(true);
                } else {
                  this.toasterService.setMessage({
                    successMessage: '',
                    errorMessage: response?.message,
                  });
                  this.spinnerService.hide();
                }
              });
          } else {
            this.spinnerService.hide();
          }
        })
        .catch((error: any) => {
          // Handle tokenization error here

          this.spinnerService.hide();
          throw error;
        });
    } catch (error: any) {
      this.spinnerService.hide();

      throw error;
    }
  }
  changeCardStatus(cardData: any) {
    this.selectedCard = cardData;
    this.selectCard.emit(cardData);
  }
}
