<div *ngIf="cameraError" class="alert alert-danger">
  {{ cameraError }}
</div>

<div
  [ngStyle]="{
    'padding-bottom':
      detailType !== 'updateJob'
        ? apidataUpdation === true && !offlineMode
          ? '150px'
          : '80px'
        : '0px'
  }"
>
  <!-- scan check points BEGIN -->
  <div *ngIf="detailType === 'scanCheckPoint'" class="mb-1">
    <div *ngIf="dupCheckpointList?.length == 0" class="my-3">
      <div class="alert alert-primary fw-bold">No Checkpoints Near You</div>
    </div>

    <div *ngIf="dupCheckpointList.length > 0">
      <div class="flex-grow-1 mb-3">
        <div class="input-group">
          <div class="form-floating" *ngIf="!distanceCalculation">
            <input
              type="text"
              class="form-control"
              id="searchCheckpoints"
              placeholder="Search Checkpoints"
              [(ngModel)]="searchFilter"
              (input)="onCPFilterApply($event)"
            />
            <label for="searchCheckpoints">Search Checkpoints</label>
          </div>
          <span
            class="input-group-text"
            (click)="onCPClearFilter()"
            *ngIf="searchFilter"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div *ngIf="nearestCheckPointsList?.length == 0" class="my-3">
        <div class="alert alert-primary fw-bold">
          No Records Match Your Search Criteria
        </div>
      </div>

      <div class="row">
        <div
          class="gy-2"
          *ngFor="let checkPoint of nearestCheckPointsList"
          (click)="openCamPopup(checkPoint)"
        >
          <div class="gt-card p-2">
            <div class="fw-bold">
              {{ checkPoint?.name }}
            </div>
            <div class="small">
              @if(checkPoint?.site_name){
              <div>{{ checkPoint?.site_name }} [{{ checkPoint?.company }}]</div>
              }@else{
              <div>
                {{ checkPoint?.company }}
              </div>
              }

              <div>
                {{ checkPoint?.full_address }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- scan check points ENDS -->

  <!-- nearestJob list section start -->
  <div *ngIf="detailType === 'updateJob'">
    <div *ngIf="apidataUpdation === true && !offlineMode">
      <div class="alert alert-primary my-3">
        <div class="fw-bold">
          Fetching Data from Server

          <div class="spinner-grow float-end" role="status"></div>
        </div>
      </div>
    </div>
    <div *ngIf="isGuardUser">
      <div
        *ngIf="dupnearestJobList?.length == 0 && !apidataUpdation"
        class="my-3"
      >
        <div class="alert alert-primary text-center">
          No Jobs Rostered Today
        </div>
        <button
          *ngIf="!offlineMode"
          class="btn btn-primary btn-lg btn-block w-100"
          routerLink="/roster"
        >
          View Roster
        </button>
      </div>
    </div>

    <div *ngIf="dupnearestJobList.length > 0">
      <div *ngIf="nearestJobList?.length">
        <gtapp-card-list
          [mobileCardUIData]="nearestJobList"
          [mobileCardDetails]="jobCardsDetails"
          [otherParams]="{ normalCard: true }"
          (rowClick)="openEventPage($event)"
        ></gtapp-card-list>
      </div>
    </div>
  </div>
  <!-- nearestJob List end -->

  <!-- Nearest Site List BEGIN -->
  <div
    *ngIf="
      ['addSitePhoto', 'addSiteComment', 'userPromptInfo'].includes(detailType)
    "
  >
    <div *ngIf="dupnearestSiteList.length === 0" class="my-3">
      <div class="alert alert-primary fw-bold">No Sites Near You</div>
    </div>

    <div *ngIf="dupnearestSiteList.length > 0" class="col-12 col-md-6 col-lg-5">
      <div class="alert alert-primary mb-2">Select Site</div>

      <div class="input-group mb-2">
        <div class="form-floating" *ngIf="!distanceCalculation">
          <input
            type="text"
            class="form-control"
            id="filterResults"
            placeholder="Filter Sites"
            [(ngModel)]="searchFilter"
            (input)="onFilterSitesApply($event)"
          />
          <label for="filterResults">Filter Sites</label>
        </div>
        <span
          class="input-group-text"
          *ngIf="searchFilter"
          (click)="onClearSitesFilter()"
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div *ngIf="nearestSiteList?.length == 0" class="my-3">
        <div class="alert alert-primary fw-bold">
          No Records Match Your Search Criteria
        </div>
      </div>

      <div class="row">
        <div
          class="gy-2 cursor-pointer"
          *ngFor="let item of nearestSiteList"
          (click)="updateSite(item)"
        >
          <div class="gt-card p-4">
            @if(item?.site_id){
            <div class="fw-semibold">{{ item?.site_name }}</div>
            <div>{{ item?.company_name }}</div>

            }@else {
            <div class="fw-semibold">{{ item?.company_name }}</div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Nearest Site List END -->
</div>
<div *ngIf="detailType !== 'updateJob'">
  <div class="bottom-section" id="fixedBtn">
    <div class="mx-2 mb-3">
      <div *ngIf="apidataUpdation === true && !offlineMode">
        <div class="alert alert-primary mb-2">
          <div class="fw-bold">
            Fetching Data from Server

            <div class="spinner-grow float-end" role="status"></div>
          </div>
        </div>
      </div>
      <button class="btn btn-dark btn-lg w-100 d-lg-none" (click)="goBack()">
        Back
      </button>
    </div>
  </div>
</div>

<ng-template #commentRef let-commentRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Add a Comment
      <span (click)="commentRef.close('close')" class="float-end">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <!--api returns an empty sites list-->
    <div *ngIf="!apidataUpdation && !nearestSiteList?.length">
      <div class="alert alert-warning">No sites found</div>
      <div class="col">
        <button
          class="btn btn-dark btn-lg w-100"
          (click)="commentRef.close('close')"
        >
          Back
        </button>
      </div>
    </div>

    <div
      *ngIf="apidataUpdation || (!apidataUpdation && nearestSiteList?.length)"
    >
      <div class="col mb-2">
        <textarea
          class="form-control"
          rows="10"
          maxlength="340"
          [disabled]="
            !apidataUpdation && !nearestSiteList?.length ? true : false
          "
          placeholder="Enter comment here"
          autofocus
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="mb-4" *ngIf="groupUserCount > 1">
        <label for="handLog">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="handOverLogDraft"
            (change)="handOverLogDraft = !handOverLogDraft"
            id="handLog"
          />
          Include in handover log</label
        >
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close('close')"
          >
            Back
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            [disabled]="!newComment"
            (click)="commentRef.close()"
          >
            Add Comment
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
