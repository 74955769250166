import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  getDistanceUsingHaversine,
  nearestJobDetail,
  sortArrayOfObject,
} from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { PrompterComponent } from '../../../../shared/components/prompter/prompter.component';
import { WebcamImageComponent } from '../../../../shared/components/web-cam-image/web-cam-image.component';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { gTDB } from '../../../../shared/services/db';
import { DeviceInfoService } from '../../../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { CheckpointService } from '../../checkpoints/checkpoint.service';
import { JobsService } from '../../jobs/jobs.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'gtapp-update-events',
  templateUrl: './update-events.component.html',
  styleUrl: './update-events.component.scss',
})
export class UpdateEventsComponent implements OnInit {
  @Input() detailType: any;

  @Input() details: any;
  @Output() emitData = new EventEmitter();
  @ViewChild('commentRef')
  commentRef!: TemplateRef<any>;
  latLon: any = {};
  gps: any;
  showMap: boolean = false;

  cameraDialog: any;
  uploadType: any;

  webcamImage: any;
  actualUploadFile: any;
  fileData: any = [];
  photoUploaded: boolean = false;
  distanceCalculation: boolean = false;

  mapInput: any;
  isAdmin: boolean = false;
  userData: any;

  //cp scan variables
  nearestCheckPointsList: any = [];
  dupCheckpointList: any = [];
  showCPFilterClose: boolean = false;

  //job update variables
  nearestJobList: any = [];
  dupnearestJobList: any = [];
  showJobFilterClose: boolean = false;

  //client update variables
  nearestSiteList: any = [];
  dupnearestSiteList: any = [];

  jobDetailsTableView: any = [
    { header: 'Distance', sortKey: 'hDistance', name: 'hDistance' },
    {
      header: 'Last Updated Date',
      sortKey: 'modified_date',
      name: 'modified_date',
      dateTimeObj: true,
    },
    {
      header: 'Site Name',
      name: 'site_name',
      sortKey: 'site_name',
    },
    {
      header: 'Client',
      name: 'company',
      sortKey: 'company',
    },

    {
      header: 'Address',
      name: 'sites',
      sortKey: 'sites',
    },
  ];
  sortKeys: any = [];
  videoDevices: any;

  cameraUsed: any;
  cameraError: any;
  videoStream: any;
  webcamImageSource: any;
  newComment: any;
  selectedSite: any;
  handOverLogDraft: boolean = false;
  stalePeriod: number = 3;
  selectedJob: any;

  userLocation: any;
  searchFilter: any;
  apidataUpdation: boolean = true;
  offlineMode: boolean = false;
  messageChannel = new MessageChannel();
  isWebCamOpened: boolean = false;
  isCommentDialogOpened: boolean = false;
  dialogref: any;
  statusData: any = [];
  // showing data as card list instead of table
  jobCardsDetails: any = {
    // text styling can be updated here

    groupDetails: (row: any) => {
      for (let i = 0; i < this.statusData.length; i++) {
        if (this.statusData[i].name === row?.status_name) {
          return {
            'border-color': `${this.statusData[i].additional_info?.style?.snubbed['border-color']}`,
          };
        }
      }

      return {};
    },
  };
  isDispatcher: boolean = false;

  completedJobCount: any;
  isGuardUser: boolean = false;

  currentDevice: any;
  promptData: any;

  cachedJobIds: any = [];

  geoFenceAlertData: any = [];

  groupUserCount = this.pageService.getGroupUserCount();
  constructor(
    private appService: AppService,
    private jobService: JobsService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private ngZone: NgZone,
    private pageService: PagesService,
    private checkpointService: CheckpointService,
    private swUpdate: SwUpdate,
    private dataCheckService: DataCheckService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private deviceService: DeviceDetectorService,
    private deviceInfoService: DeviceInfoService,
    private swPush: SwPush
  ) {
    // CLOSE THE CAMERA DIALOG IF THE BACK BUTTON IS PRESSED
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.cameraDialog?.close();
      }
    });

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatcher = this.dataCheckService.isDispatchUser();
    this.isGuardUser = this.dataCheckService.isGuardUser();

    this.currentDevice = this.deviceService.getDeviceInfo();
    this.deviceInfoService.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.offlineMode = !value?.appStatus;
      }
    });
  }

  async ngOnInit() {
    //check whether offline and localhost, if so change apidataUpdation = false
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.apidataUpdation = false;
    }
    if (this.detailType === 'userPromptInfo') {
      if (this.details?.promptData?.id) {
        this.getNearestSiteList();
        this.promptData = JSON.parse(JSON.stringify(this.details?.promptData));
      } else {
        this.clearEverythingGoBack();
      }
    }
    if (this.detailType === 'scanCheckPoint') {
      this.getNearestCheckpoints();
    }

    if (this.detailType === 'updateJob') {
      this.gtJobStatuses();
    }
    if (['addSitePhoto', 'addSiteComment'].includes(this.detailType)) {
      if (this.detailType == 'addSitePhoto') {
        this.spinnerService.show();
        this.isWebCamOpened = true;
        setTimeout(() => {
          this.openCamPopupDialog();
          this.getNearestSiteList();
          this.spinnerService.hide();
        }, 500);
      }
      if (this.detailType == 'addSiteComment') {
        this.isCommentDialogOpened = true;
        setTimeout(() => {
          this.openAddCommentTemplate(this.commentRef);
          this.getNearestSiteList();
        }, 100);
      }
    }
    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_PORT',
      },
      [this.messageChannel.port2]
    );

    // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      this.formatNewApiData(event?.data);
      if (
        !this.cameraDialog &&
        !this.newComment &&
        !this.webcamImage &&
        this.nearestSiteList?.length
      ) {
        if (this.detailType == 'addSiteComment') {
          setTimeout(() => {
            if (!this.cameraDialog && !this.isCommentDialogOpened) {
              this.openAddCommentTemplate(this.commentRef);
            }
          }, 500);
        }
        if (this.detailType == 'addSitePhoto') {
          setTimeout(() => {
            if (!this.cameraDialog && !this.isWebCamOpened) {
              this.openCamPopupDialog();
            }
          }, 500);
        }
      }
    };
    this.pageService.miscSubjectParam.next({
      isOnEvent: this.detailType !== 'updateJob',
    });

    this.checkJobsPresentInIndexedDb();
  }
  checkJobsPresentInIndexedDb() {
    if (this.swPush.isEnabled) {
      gTDB.cachedData
        .toArray()

        .then((response: any) => {
          const cachedJobs = response?.filter((item: any) =>
            [
              '/api/roster_schedule/get_patrol_route_detail/',
              '/api/job/get_detail/',
            ].includes(item?.shortPath)
          );
          this.cachedJobIds = cachedJobs?.map((job: any) => job?.data?.id);
        });
    }
  }

  goBack(val = false) {
    this.spinnerService.hide();
    this.emitData.emit(val);
  }

  async openCamPopup(checkPoint: any) {
    this.cameraDialog = this.dialogService.open(WebcamImageComponent, {
      data: { showFrom: 'scanCheckpoint' },
    });

    this.cameraDialog.afterClosed()?.subscribe((value: any) => {
      if (value && value !== 'error') {
        this.openUserPrompt(checkPoint, value);
      } else if (value == 'error') {
        this.cameraError = 'ERROR: Unable to access your camera';
      }
    });
  }
  getSortedPromtList(arrayList: any) {
    return sortArrayOfObject(arrayList, 'order', 'desc');
  }
  openUserPrompt(checkPoint: any, scannedRecord?: any, qrValue?: any) {
    if (checkPoint?.user_prompt_count > 0) {
      let promptResults: any = [];
      let dialogRefs: any = [];
      let counter = 0;
      this.getSortedPromtList(checkPoint?.user_prompts)?.forEach(
        (prompt: any) => {
          const dialogref = this.dialogService.open(PrompterComponent, {
            data: { promptData: prompt?.prompts },
          });
          dialogRefs.push(dialogref);
          dialogref.afterClosed().subscribe((value: any) => {
            if (value) {
              counter += 1;
              if (value?.length) {
                promptResults.push({
                  data: value,
                  prompt_data: { id: prompt?.id, name: prompt?.name },
                });
              }
              if (counter === checkPoint?.user_prompt_count) {
                this.scanCheckPoint(checkPoint, scannedRecord, promptResults);
              }
            } else {
              if (value === false) {
                dialogRefs?.forEach((dialogRef: any) => dialogRef?.close());
                this.toasterService.setMessage({
                  successMessage: '',
                  errorMessage: 'Checkpoint was not scanned',
                  status: 'warning',
                });
                this.clearEverythingGoBack();
              }
            }
          });
        }
      );
    } else {
      this.scanCheckPoint(checkPoint, scannedRecord);
    }
  }
  scanCheckPoint(checkPoint: any, scannedRecord?: any, promptResults?: any) {
    this.webcamImage = scannedRecord?.webCamImage;
    this.actualUploadFile = scannedRecord?.actualFileUpload;
    this.uploadType = scannedRecord?.uploadType;
    if (this.actualUploadFile) {
      this.spinnerService.show('Uploading Details');
      checkPoint.prompt_data = promptResults;
      this.onFinalSubmit(checkPoint);
    }
  }

  onFinalSubmit(checkPoint?: any) {
    let fileData: FormData = new FormData();
    fileData.append('file', this.actualUploadFile);
    fileData.append('check_point_id', checkPoint?.id);
    fileData.append('image_source', this.uploadType);
    if (checkPoint?.prompt_data?.length) {
      fileData.append('prompt_data', JSON.stringify(checkPoint?.prompt_data));
    }
    fileData.append(
      'checkpointDetail',
      JSON.stringify({
        name: checkPoint?.name,
        company: checkPoint?.company,
        site_name: checkPoint?.site_name,
      })
    );

    if (this.details?.patrolRouteId) {
      fileData.append('patrol_route_id', this.details?.patrolRouteId);
    }
    this.spinnerService.show('Uploading Details');
    this.appService
      .formDataApi('patrol/scan_checkpoint', fileData)
      .then((response: any) => {
        if (response['status'] === 'success') {
          this.actualUploadFile = null;
          this.cameraDialog.close();

          this.clearEverythingGoBack(response);
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  updateSite(site: any) {
    this.spinnerService.show();
    this.selectedSite = site;
    if (this.detailType == 'addSiteComment') {
      this.addComment(this.detailType);
    } else if (this.detailType == 'addSitePhoto') {
      this.onFileSubmitUpdate(this.detailType);
    } else if (
      this.detailType == 'userPromptInfo' &&
      this.promptData?.prompts?.length
    ) {
      this.spinnerService.hide();
      let promptResults: any = [];
      let counter = 0;

      this.dialogref = this.dialogService.open(PrompterComponent, {
        data: { promptData: this.promptData?.prompts },
      });
      this.dialogref.afterClosed().subscribe((value: any) => {
        if (value) {
          counter += 1;
          if (value?.length) {
            promptResults.push({
              data: value,
              prompt_data: {
                id: this.promptData?.id,
                name: this.promptData?.name,
              },
            });
          }
          if (counter === this.promptData?.prompts?.length) {
            this.spinnerService.show();
            let body: any = {
              company_id: this.selectedSite.id,
              prompt_data: JSON.stringify(promptResults),
              user_prompt_id: this.promptData?.id,
              company: this.selectedSite?.company_name,
              site_name: this.selectedSite?.site_name,
              site_id: this.selectedSite?.site_id,
            };
            if (this.details?.patrolRouteId) {
              body.patrol_route_id = this.details?.patrolRouteId;
            }
            this.checkpointService.updateClient(body).then((response: any) => {
              this.clearEverythingGoBack(response);
            });
          }
        } else {
          this.goBack();
        }
      });
    } else {
      this.goBack();
    }
  }
  openEventPage(event: any) {
    this.goBack(true);

    if (
      this.offlineMode &&
      !this.cachedJobIds?.includes(String(event.event_id))
    ) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage:
          'Unable to fetch details from server. Please try again later',
      });
      return;
    }
    if (event?.job_type_name.toLowerCase() === 'patrol') {
      this.router.navigate(['/view-route', { rKey: String(event.event_id) }]);
    } else {
      this.router.navigateByUrl(`/job-detail/${event.job_id}`, {
        state: event.event_id,
      }),
        window.localStorage.setItem('urlId', event.event_id);
    }
  }
  clearEverythingGoBack(response?: any) {
    if (response) {
      if (response?.status == 'success') {
        this.toasterService.setMessage({
          successMessage: this.offlineMode
            ? 'Action Queued'
            : response?.message,
          errorMessage: '',
        });
      } else {
        this.toasterService.setMessage({
          errorMessage: response?.message,
          successMessage: '',
        });
      }
    }
    if (this.handOverLogDraft) {
      // to minimize unnecessary calls to backend to check for drafts
      sessionStorage.setItem('handOverLogUpdate', '1');
    }

    this.searchFilter = null;

    this.webcamImage = null;
    this.selectedSite = null;
    this.selectedJob = null;
    this.newComment = null;
    this.goBack();
    this.spinnerService.hide();
  }

  openAddCommentTemplate(requestRef: TemplateRef<any>) {
    this.cameraDialog = this.dialogService.open(
      requestRef,
      {
        data: {},
      },
      this.viewContainerRef
    );
    this.spinnerService.hide();
    this.cameraDialog.afterClosed().subscribe((value: any) => {
      if (value == 'close') {
        this.clearEverythingGoBack();
      }
    });
  }
  addComment(detailType: any) {
    this.spinnerService.show();
    if (detailType === 'addSiteComment') {
      let body: any = {
        company_id: this.selectedSite.id,
        comment: this.newComment,
        company: this.selectedSite?.company_name,
        site_name: this.selectedSite?.site_name,
        site_id: this.selectedSite?.site_id,
        hand_over_log_draft: String(this.handOverLogDraft),
      };
      if (this.details?.patrolRouteId) {
        body.patrol_route_id = this.details?.patrolRouteId;
      }
      this.checkpointService.updateClient(body).then((response: any) => {
        this.clearEverythingGoBack(response);
      });
    }
  }
  openCamPopupDialog() {
    this.cameraDialog = this.dialogService.open(WebcamImageComponent, {
      data: { showFrom: 'updateClient' },
    });

    this.cameraDialog.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.webcamImage = value.webCamImage;
        this.actualUploadFile = value.actualFileUpload;
        this.uploadType = value?.uploadType;
        this.newComment = value.newComment;
        this.handOverLogDraft = value?.handOverLogDraft;
      } else if (value == 'error') {
        this.cameraError = 'Unable to access your camera';
      } else {
        this.goBack();
      }
    });
  }
  fileUpload(url: string, fileData: any) {
    this.appService.formDataApi(url, fileData).then((response: any) => {
      this.clearEverythingGoBack(response);
    });
  }
  //Method to upload the photo for selected site or job
  onFileSubmitUpdate(detailType: any) {
    this.spinnerService.show('Uploading Details');
    let fileData: FormData = new FormData();
    fileData.append('file', this.actualUploadFile);
    if (this.newComment) {
      fileData.append('comment', this.newComment);
    }
    let url: any;
    if (detailType === 'addSitePhoto') {
      url = `patrol/add_update`;

      fileData.append('company_id', this.selectedSite?.id);
      fileData.append('company', this.selectedSite?.company_name);

      fileData.append('site_name', this.selectedSite?.site_name);
      fileData.append('site_id', this.selectedSite?.site_id);
    }
    if (this.details?.patrolRouteId) {
      fileData.append('patrol_route_id', this.details?.patrolRouteId);
    }
    fileData.append('image_source', this.uploadType);
    fileData.append('hand_over_log_draft', String(this.handOverLogDraft));
    if (url && fileData) {
      this.fileUpload(url, fileData);
    }
  }

  async getFormattedData(dupList: any, _type: string, gps: any) {
    return new Promise(async (resolve) => {
      dupList?.forEach((item: any) => {
        item.hDistance = Number(
          getDistanceUsingHaversine(
            gps?.lat,
            item?.latitude,
            gps?.lon,
            item?.longitude
          )
        );
      });
      if (_type == 'cp') {
        dupList = await this.formatCPList(dupList);
        this.dupCheckpointList = dupList;
      }
      if (_type == 'job') {
        this.dupnearestJobList = [...dupList];
      }
      if (_type == 'site') {
        this.dupnearestSiteList = [...dupList];
      }

      resolve(sortArrayOfObject(dupList, 'hDistance', 'asc'));
    });
  }
  async formatCPList(dupList: any) {
    return new Promise((resolve) => {
      resolve(dupList?.filter((cp: any) => cp?.hDistance <= cp?.max_distance));
    });
  }

  fetchLocationAndCalculateDistance(dupList: any, _type = 'cp') {
    if (dupList?.length > 0) {
      this.distanceCalculation = true;
      this.deviceInfoService.getGpsCoordinates().then(async (gps: any) => {
        let sortedList: any = await this.getFormattedData(dupList, _type, gps);

        if (_type == 'cp') {
          this.nearestCheckPointsList = sortedList;
        }
        if (_type == 'site') {
          this.nearestSiteList = sortedList;
        }
        if (_type == 'job') {
          this.nearestJobList = sortedList;
        }
        this.distanceCalculation = false;
      });
    }
  }
  updateIndexedDB(jobList: any) {
    // send a list of active jobIds to service worker
    //so that the db doesn't have to keep stale jobs in it
    let idList = jobList?.map((job: any) => job?.event_id);
    if (idList?.length) {
      navigator?.serviceWorker?.controller?.postMessage({
        type: 'removeStaleJobs',
        idList: idList,
      });
    }
  }
  formatNewApiData(eventData: any) {
    if (eventData?.shortPath) {
      if (eventData?.shortPath == '/api/job/') {
        if (eventData?.data) this.formatJobStatuses(eventData);
      } else {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.apidataUpdation = false;
            this.pageService.miscSubjectParam.next({
              addPhotoClientCount: {
                apidataUpdation: this.apidataUpdation,
                clientCount: this.nearestSiteList?.length,
              },
            });
          }, 100);
        });

        if (eventData?.shortPath == '/api/job/list_nearest/') {
          this.dupnearestJobList = eventData?.data || [];
          this.completedJobCount = eventData?.completed_count;
          this.updateIndexedDB(this.dupnearestJobList);
          this.nearestJobList = eventData?.data || [];
          this.gatherGeoFenceAlertData();
        }
        if (eventData?.shortPath == '/api/site/list_nearest/') {
          this.nearestSiteList = this.filterPatrolEvents(
            eventData?.data,
            'client'
          );
          this.dupnearestSiteList = this.nearestSiteList;
        }
        if (eventData?.shortPath == '/api/check_point/list_nearest/') {
          this.dupCheckpointList = this.filterPatrolEvents(eventData?.data);
          this.nearestCheckPointsList = this.dupCheckpointList;
          this.fetchLocationAndCalculateDistance(this.dupCheckpointList);
        }
      }
    }
  }
  gatherGeoFenceAlertData() {
    this.geoFenceAlertData = [];

    this.nearestJobList?.forEach((jobData: any) => {
      // (IDENTITY_NORMAL = 0), _('NORMAL');
      // IDENTITY_COMPLETE = 1, _("COMPLETE")
      // IDENTITY_CANCELLED_BY_SUBSCRIBER = 2, _("CANCELLED BY SUBSCRIBER")
      // IDENTITY_CANCELLED_BY_LC = 3, _("CANCELLED BY LC")
      // IDENTITY_ONSITE = 4, _("ONSITE")
      // IDENTITY_OFFSITE = 5, _("OFFSITE")
      // IDENTITY_REOPEN = 6, _("RE OPEN")
      // IDENTITY_DELETE = 7, _("DELETE")
      // IDENTITY_IN_TRANSIT = 8, _("IN TRANSIT")
      const statusCheck = Boolean(
        !jobData?.status_identity ||
          ['0', '4', '5', '8', '6'].includes(jobData?.status_identity)
      );

      if (
        jobData?.schedule_details?.additional_info?.geofence_distance > 0 &&
        statusCheck
      ) {
        const startDate =
          jobData?.schedule_details?.event_date ||
          jobData?.schedule_details?.start_day;
        const endDate =
          jobData?.schedule_details?.event_date ||
          jobData?.schedule_details?.end_day;

        let startDateTime = new Date(
          startDate + ' ' + jobData?.schedule_details?.start_time
        );

        let endDateTime = new Date(
          endDate + ' ' + jobData?.schedule_details?.end_time
        );
        if (startDateTime.getTime() > endDateTime.getTime()) {
          endDateTime.setDate(endDateTime.getDate() + 1);
        }

        this.geoFenceAlertData.push({
          id: jobData?.event_id,
          startDateTime: startDateTime.getTime(),
          endDateTime: endDateTime.getTime(),
          gps: jobData?.schedule_details?.gps,
          geoFenceDistance:
            jobData?.schedule_details?.additional_info?.geofence_distance,
        });
      }
    });
    localStorage.setItem(
      btoa('geoFenceAlertData'),
      JSON.stringify(this.geoFenceAlertData)
    );
  }

  filterPatrolEvents(eventData: any, eventType: string = 'checkpoint') {
    if (!this.details?.filterPatrolData) return eventData;
    if (eventType === 'checkpoint') {
      return eventData?.filter((item: any) =>
        this.details?.filterPatrolData?.checkpoint?.includes(item?.id)
      );
    } else {
      return eventData?.filter(
        (item: any) =>
          (this.details?.filterPatrolData?.company?.includes(item?.id) &&
            item?.default) ||
          (item?.site_id &&
            this.details?.filterPatrolData?.site?.includes(item?.site_id))
      );
    }
  }

  // checkpoints section

  getNearestCheckpoints() {
    let body: any = {};
    // if (this.details?.patrolRouteId) {
    //   body.patrol_route_id = this.details?.patrolRouteId;
    // }
    this.spinnerService.show();
    this.jobService.getNearestCheckpointList(body).then((response: any) => {
      if (response['status'] === 'success') {
        this.dupCheckpointList = this.filterPatrolEvents(response?.data);
        this.nearestCheckPointsList = this.dupCheckpointList;
        this.fetchLocationAndCalculateDistance(this.dupCheckpointList);
        if (response?.message) {
          this.toasterService.setMessage({
            successMessage: response?.message,
            errorMessage: '',
          });
        }
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
      this.spinnerService.hide();
    });
  }

  onCPFilterApply(event: any) {
    if (event?.target?.value?.length === 0) {
      this.onCPClearFilter();
    } else {
      const searchString = event?.target?.value?.toLowerCase();
      this.nearestCheckPointsList = this.dupCheckpointList?.filter(
        (item: any) =>
          item?.name?.toLowerCase()?.includes(searchString) ||
          item?.company?.toLowerCase()?.includes(searchString) ||
          item?.site_name?.toLowerCase()?.includes(searchString) ||
          item?.full_address?.toLowerCase()?.includes(searchString)
      );
    }
  }
  onCPClearFilter() {
    this.searchFilter = null;
    this.nearestCheckPointsList = this.dupCheckpointList;
    this.showCPFilterClose = false;
  }

  // site update section
  getNearestSiteList() {
    let body: any = {};

    this.jobService.getNearesSiteList(body).then((response: any) => {
      if (response['status'] === 'success') {
        this.nearestSiteList = this.filterPatrolEvents(
          response?.data,
          'client'
        );
        this.dupnearestSiteList = this.nearestSiteList;
        this.pageService.miscSubjectParam.next({
          addPhotoClientCount: {
            apidataUpdation: this.apidataUpdation,
            clientCount: this.nearestSiteList?.length,
          },
        });
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  onFilterSitesApply(event: any) {
    if (event?.target?.value?.length === 0) {
      this.onClearSitesFilter();
    } else {
      const searchString = event?.target?.value?.toLowerCase();

      this.nearestSiteList = this.dupnearestSiteList?.filter(
        (item: any) =>
          item?.full_address?.toLowerCase().includes(searchString) ||
          item?.company_name?.toLowerCase().includes(searchString) ||
          item?.site_name?.toLowerCase().includes(searchString) ||
          item?.site_address?.toLowerCase().includes(searchString)
      );
    }
  }
  onClearSitesFilter() {
    this.searchFilter = null;
    this.nearestSiteList = this.dupnearestSiteList;
  }

  // job update section
  onJobFilterApply(event: any) {
    if (event?.target?.value?.length === 0) {
      this.onJobClearFilter();
    } else {
      this.showJobFilterClose = true;
      this.nearestJobList = this.nearestJobList?.filter(
        (item: any) =>
          item?.company?.toLowerCase().includes(event?.target?.value) ||
          item?.sites?.toLowerCase().includes(event?.target?.value) ||
          item?.job_key?.toLowerCase().includes(event?.target?.value)
      );
    }
  }
  onJobClearFilter() {
    this.searchFilter = null;
    this.showJobFilterClose = false;
    this.nearestJobList = this.dupnearestJobList;
  }
  formatJobStatuses(response: any) {
    this.statusData = response?.statuses;

    this.statusData.push({
      id: '0',
      name: 'Closed',
      additional_info: {
        style: {
          snubbed: {
            'color': 'var(--color-basic-1100)',
            'background-color': 'var(--color-basic-100)',
            'border-color': 'var(--color-basic-1100)',
            'border': '1px solid var(--color-basic-1100)',
          },
        },
      },
    });
  }
  gtJobStatuses() {
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.formatJobStatuses(response);

        this.getNearestJobs();
      }
    });
  }

  getNearestJobs() {
    this.jobCardsDetails.individualDetails = nearestJobDetail(
      this.statusData,
      this.stalePeriod
    );
    this.spinnerService.show();

    this.jobService
      .getNearestJobs({ time_offset: new Date().getTimezoneOffset() })
      .subscribe((response: any) => {
        if (response['status'] === 'success') {
          this.spinnerService.hide();
          this.dupnearestJobList = response['data'] || [];
          this.completedJobCount = response?.completed_count;
          this.nearestJobList = this.dupnearestJobList;
          this.gatherGeoFenceAlertData();
          if (response?.message) {
            this.toasterService.setMessage({
              successMessage: response?.message,
              errorMessage: '',
            });
          }
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
}
