<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="mb-2 me-sm-2">
    <button
      class="btn btn-primary btn-lg w-100"
      (click)="openAddResponseForm()"
    >
      + Add Response Type
    </button>
  </div>
  <div class="flex-sm-grow-1" *ngIf="initialList?.length > 5">
    <gtapp-auto-complete
      [data]="responseTypeDropDownList"
      placeHolder="Filter / Search"
      searchKeyword="name"
      [itemTemplate]="responseTypeTemplate"
      (inputChanged)="onResponseTypeLookup($event)"
      (selected)="openEditResponseForm($event)"
      (submitted)="filterResults($event)"
      [enableSearchCloseOption]="true"
      (fieldCleared)="filterResults('')"
    >
    </gtapp-auto-complete>
    <ng-template #responseTypeTemplate let-item>
      <div>{{ item?.name }}</div>
    </ng-template>
  </div>
</div>

<ng-container *ngIf="initialList?.length > 0">
  <gtapp-card-list
    [mobileCardUIData]="responseTypeList"
    [mobileCardDetails]="cardDetails"
    [mobileActionIcons]="cardActionIcons"
    [otherParams]="{ paginationData: true }"
    (actionClick)="deleteResponse($event?.event)"
    (rowClick)="openEditResponseForm($event)"
  ></gtapp-card-list>
</ng-container>
