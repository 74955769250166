<div *ngIf="showArchivedList" class="fw-bold text-center my-3" role="alert">
  Showing Deleted Sites
</div>
<div class="d-flex flex-column flex-md-row input-btn-group">
  <div class="ms-md-2 mb-2 mb-lg-0">
    <button class="btn btn-primary btn-lg w-100" (click)="openAddSiteForm()">
      + Add Site
    </button>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0" *ngIf="archivedCount && !clientId">
    <button
      class="btn btn-secondary btn-lg w-100"
      (click)="getArchivedUnarchivedList()"
    >
      {{ showArchivedList ? "Show Active" : "Show Deleted" }}
    </button>
  </div>
  <div class="flex-md-grow-1 mb-2 mb-md-0 ms-lg-2">
    <gtapp-auto-complete
      [data]="siteDropDownList"
      placeHolder="Search Sites"
      searchKeyword="company_name"
      [itemTemplate]="userTemplate"
      (inputChanged)="onSiteLookup($event)"
      (selected)="openSiteView($event)"
      (submitted)="globalFilter = $event; sitePaginationData = {}; getSites()"
      [enableSearchCloseOption]="true"
      (fieldCleared)="globalFilter = null; sitePaginationData = {}; getSites()"
    >
    </gtapp-auto-complete>

    <ng-template #userTemplate let-item>
      <div>{{ item?.company_name }} [{{ item?.company?.company_name }}]</div>
    </ng-template>
  </div>
</div>

@if (!largeView) {
<div>
  <gtapp-card-list
    [mobileCardBackendData]="sitePaginationData[pageNumSite]"
    [mobileCardDetails]="siteCardViewDetail"
    [otherParams]="otherParams"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    (rowClick)="openSiteView($event)"
    (paginationChange)="onChangePagination($event)"
  ></gtapp-card-list>
</div>
}@else {
<!-- DESKTOP VIEW -->
<div>
  <gtapp-gt-table
    [desktopTableBackendData]="sitePaginationData[pageNumSite]"
    [desktopTableDetails]="siteTableDetail"
    [tableStyle]="tableStyle"
    [otherParams]="otherParams"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    (rowClick)="openSiteView($event)"
    (paginationChange)="onChangePagination($event)"
  ></gtapp-gt-table>
</div>
}
