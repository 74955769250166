<div class="card dialog-box">
  <div class="card-header">
    <span>
      {{ editUser ? "User Details" : "Add User" }}
    </span>
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <div>
    <!-- Add User Form -->
    <ng-container *ngIf="!editUser">
      <form [formGroup]="userForm" #f="ngForm">
        <div class="row gy-3 gx-0">
          <div class="form-floating">
            <input
              type="email"
              class="form-control"
              id="email"
              formControlName="email"
              autocomplete="off"
              placeholder="Enter email"
              autofocus
              autocapitalize="off"
            />
            <label for="email">Email</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="first_name"
              formControlName="first_name"
              autocomplete="off"
              placeholder="First Name"
              autofocus
            />
            <label>First Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              id="last_name"
              formControlName="last_name"
              autocomplete="off"
              placeholder="Last Name"
              autofocus
            />
            <label>Last Name</label>
          </div>
        </div>

        <div class="form-group mt-2">
          <label for="group_ids"> <dt>User Groups</dt></label>
          <div class="row row-cols-auto">
            <div *ngFor="let grp of userGroupListData">
              <label for="{{ grp?.id }}">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="grp?.selected"
                  id="{{ grp?.id }}"
                  (change)="grp.selected = !grp?.selected"
                />
                {{ grp.name }}</label
              >
            </div>
          </div>
        </div>

        <div class="row row-cols-2 mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="onClearForm()"
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              [disabled]="!userForm.valid"
              class="btn btn-lg btn-primary w-100"
              (click)="sendAddUserLink()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- User Details -->
    <ng-container *ngIf="editUser">
      <div class="text-center">
        <img
          *ngIf="detailData?.profile_image"
          src="{{ detailData?.profile_image }}"
          alt="User Profile Image"
          class="rounded-circle"
          style="height: 120px"
        />
      </div>

      <div class="details-card">
        <div>
          <dl>
            <dt>Name</dt>
            <dd class="fw-bold">
              @if(userFullName ){
              {{ userFullName }}
              }@else {[USER NOT SIGNED-IN YET]}
            </dd>
          </dl>
        </div>

        <ng-container *ngIf="detailData?.user_app_status">
          <div>
            <dl>
              <dt>Status</dt>
              <dd>
                {{ detailData?.user_app_status | titlecase }}
                {{
                  detailData?.last_activity_time | customDate : "d MMM y HH:mm"
                }}
              </dd>
            </dl>
          </div>
        </ng-container>

        <dl>
          <div class="row align-items-center">
            <div>
              <dt>Email</dt>
              <dd>
                <a
                  href="mailto:{{ detailData?.email }}"
                  class="text-decoration-none"
                  >{{ detailData?.email }}</a
                >
              </dd>
            </div>

            <ng-container *ngIf="isUserAboveManager">
              <div class="form-check mt-3">
                <span role="button">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="button"
                    (change)="changeDefaultReportRecipent()"
                    [checked]="detailData?.default_report_recipient"
                    id="reportRecipient"
                  />
                  <label
                    for="reportRecipient"
                    class="form-check-label"
                    role="button"
                  >
                    Make a default recipient when creating new reports</label
                  >
                </span>
              </div>

              <div class="row row-cols-2" *ngIf="detailData?.is_registered">
                <div class="col">
                  <button
                    class="btn btn-md btn-warning w-100"
                    (click)="addRemoveReportRecipients('remove')"
                    [disabled]="
                      !reportsConfigured?.user_already_in_some_reports
                    "
                  >
                    Remove from all reports
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-md btn-success w-100"
                    (click)="addRemoveReportRecipients('add')"
                    [disabled]="reportsConfigured?.user_in_all_reports"
                  >
                    Add to all existing scheduled reports
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </dl>

        <ng-container *ngIf="detailData?.mobile_number">
          <dl>
            <div class="row row-cols-2 align-items-center">
              <div class="col-10">
                <dt>Mobile</dt>
                <dd>
                  <a href="tel:+{{ detailData?.mobile_number }}">{{
                    detailData?.mobile_number
                  }}</a>
                </dd>
              </div>
              <div class="col-2 text-center">
                <a href="tel:+{{ detailData?.mobile_number }}">
                  <i class="fa-solid fa-phone-volume fa-2x text-success"></i>
                </a>
              </div>
            </div>
          </dl>
        </ng-container>

        <ng-container *ngIf="isAdmin && fromUserPage">
          <dl>
            <dt>User Groups</dt>
            <dd>
              <div class="row row-cols-auto ms-1">
                <div
                  *ngFor="let grp of userGroupListData"
                  class="form-check"
                  role="button"
                  (click)="
                    addRemoveGroup(grp?.id, grp?.selected ? 'remove' : 'add')
                  "
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="button"
                    [checked]="grp?.selected"
                    [disabled]="grp?.disabled"
                    id="{{ grp?.id }}"
                  />
                  {{ grp.name }}
                  <label
                    for="{{ grp?.id }}"
                    class="form-check-label me-3"
                  ></label>
                </div>
              </div>
            </dd>
          </dl>
          <!-- <dl>

            <dt>Welfare Checks</dt>
            <dd>{{ detailData?.interval_check }} mins</dd>
          </dl> -->
        </ng-container>

        <ng-container *ngIf="isAdmin && !fromUserPage">
          <dl>
            <dt>User Groups</dt>
            <dd>
              <span *ngFor="let grp of detailData?.user_group; let l = last">
                <span>{{ grp?.name }} <span *ngIf="!l">, </span></span>
              </span>
            </dd>
          </dl>
        </ng-container>
      </div>
      <ng-container
        *ngIf="!detailData?.is_registered && isAdmin && fromUserPage"
      >
        <div class="col text-center">
          <button
            class="btn btn-primary btn-lg"
            (click)="reInviteUser(detailData)"
          >
            Resend User Sign In Link
          </button>
        </div>
      </ng-container>
      <div class="row row-cols-2 mt-2" *ngIf="!isOwner && fromUserPage">
        <div class="col">
          <button
            class="btn btn-lg btn-warning w-100"
            (click)="disableOrEnableUser(detailData?.id)"
          >
            {{ detailData?.is_active ? "Disable Sign-In" : "Enable Sign-In" }}
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-lg btn-danger w-100"
            (click)="deleteUserById(detailData)"
          >
            Delete User
          </button>
        </div>
      </div>

      <div class="text-center mt-3" *ngIf="!detailData?.license?.length">
        <div class="alert alert-warning">
          This user has not uploaded any Security Licences on their profile
        </div>
      </div>

      <!-- List of User Licenses -->
      <div *ngIf="detailData.license?.length">
        <hr />
        <div class="fw-bold">Licences</div>
        <gtapp-add-edit-license-details
          [licensesDataList]="detailData?.license"
          [showLicensesList]="true"
          showFrom="userDetailPage"
        ></gtapp-add-edit-license-details>
      </div>
    </ng-container>
  </div>
</div>
