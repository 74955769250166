import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataCheckService } from '../../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { getDateTimeRange } from '../../../tableDetails';
import { HolAndChatService } from '../../hol-and-chat.service';

@Component({
  selector: 'gtapp-hand-over-log',
  templateUrl: './hand-over-log.component.html',
  styleUrl: './hand-over-log.component.scss',
})
export class HandOverLogComponent {
  //sent hol variables
  totalRowsHOLSent: number = 0;
  previousHOLSent: number = 0;
  pageSizeHOLSent: number = 10;
  pageNumHOLSent: number = 1;
  totalPagesHOLSent: number = 0;
  searchParamsHOLSent: any;
  sortKeysHOLSent: any = [];
  dateRangeHOLSent: any;

  otherParamsHOLSent: any = {};
  sentHOLDataList: any;
  tableDetailHOLSent = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'hand_over_log__updated_at',
      sortKey: 'hand_over_log__updated_at',
      default: true,
      ...getDateTimeRange(3),
    },
    {
      header: 'To',
      name: 'received_by',
      searchKey: 'received_by_full_name',
      sortKey: 'received_by_full_name',
    },
    {
      header: 'Status',
      nestedValue: (row: any) => {
        return row?.accepted_at ? '[READ]' : '[UNREAD]';
      },
      sortKey: 'read_at',
    },
  ];

  //received hol variables
  totalRowsHOLReceived: number = 0;
  previousHOLReceived: number = 0;
  pageSizeHOLReceived: number = 10;
  pageNumHOLReceived: number = 1;
  totalPagesHOLReceived: number = 0;
  searchParamsHOLReceived: any;
  sortKeysHOLReceived: any = [];
  dateRangeHOLReceived: any;

  otherParamsHOLReceived: any = {};
  receivedHOLDataList: any = [];

  tableDetailHOLReceived = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'hand_over_log__updated_at',
      sortKey: 'hand_over_log__updated_at',
      default: true,
      ...getDateTimeRange(3),
    },
    {
      header: 'From',
      name: 'created_by',
      searchKey: 'created_by_full_name',
      sortKey: 'created_by_full_name',
    },
    {
      header: 'Status',
      nestedValue: (row: any) => {
        return row?.accepted_at ? '[READ]' : '[UNREAD]';
      },
      sortKey: 'read_at',
    },
  ];

  //received & sent hol variables
  totalRowsHOLReceivedAndSent: number = 0;
  previousHOLReceivedAndSent: number = 0;
  pageSizeHOLReceivedAndSent: number = 10;
  pageNumHOLReceivedAndSent: number = 1;
  totalPagesHOLReceivedAndSent: number = 0;
  searchParamsHOLReceivedAndSent: any;
  sortKeysHOLReceivedAndSent: any = [];
  dateRangeHOLReceivedAndSent: any;

  otherParamsHOLReceivedAndSent: any = {};
  receivedAndSentHOLDataList: any = [];

  tableDetailHOLReceivedAndSent = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'hand_over_log__updated_at',
      sortKey: 'hand_over_log__updated_at',
      default: true,
      ...getDateTimeRange(3),
    },
    {
      header: 'From',
      name: 'created_by',
      searchKey: 'created_by_full_name',
      sortKey: 'created_by_full_name',
    },
    {
      header: 'To',
      name: 'received_by',
      searchKey: 'received_by_full_name',
      sortKey: 'received_by_full_name',
    },
    {
      header: 'Status',
      nestedValue: (row: any) => {
        return row?.accepted_at ? '[READ]' : '[UNREAD]';
      },
      sortKey: 'read_at',
    },
  ];
  isAdmin = this.dataCheckService.isUserAdmin();
  holDraftCount: number = 0;

  selectedTab: any;
  tabNames: any = {};

  groupUserCount: any;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private handOverLogService: HolAndChatService,
    private router: Router,
    private dataCheckService: DataCheckService
  ) {}
  ngOnInit(): void {
    this.getHOLDraftedCount();
    this.getHOLReceivedData();
    this.getHOLSentData();
    this.tabNames = {
      tab1: 'Received',
      tab2: 'Sent',
    };
    if (this.isAdmin) {
      this.tabNames = {
        tab1: 'Received',
        tab2: 'Sent',
        tab3: 'All Logs',
      };
      this.getHOLReceivedAndSentData();
    }

    let savedTab = sessionStorage.getItem('selectedTab');

    this.changeTab(savedTab);
  }

  changeTab(tab: any) {
    this.selectedTab = Object.values(this.tabNames)?.includes(tab)
      ? tab
      : this.tabNames.tab1;

    sessionStorage.setItem('selectedTab', this.selectedTab);
  }

  onRowClick(data: any) {
    this.router.navigate([
      '/view-hand-over-log',
      {
        hKey: btoa(String(data?.id)),
        gKey: btoa(String(data?.received_by_id)),
      },
    ]);
  }
  getHOLDraftedCount() {
    this.spinnerService.show();
    this.handOverLogService
      .getHandOverLogs({}, { drafted_count: 1 })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.holDraftCount = response?.data?.hand_over_log_drafted_count;
          this.handOverLogService.updateHOLDraftCount(this.holDraftCount);
          this.spinnerService.hide();

          //  (USER_GROUP_CUSTOM = 0), _('Custom');
          //  (USER_GROUP_MANAGERS = 1), _('Managers');
          //  (USER_GROUP_GUARDS = 2), _('Guards');
          //  (USER_GROUP_DISPATCHERS = 3), _('Dispatchers');
          //  (USER_GROUP_ADMINISTRATOR = 4), _('Administrator');
          this.groupUserCount = response?.user_and_group_count?.['2'];
          sessionStorage.setItem(
            'groupUserCount',
            JSON.stringify(response?.user_and_group_count)
          );
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  getHOLSentData() {
    let params: any = { sent: 1 };
    let body: any = {};
    if (this.dateRangeHOLSent) body.date_range = this.dateRangeHOLSent;
    if (this.searchParamsHOLSent) {
      body.ui_filters = this.searchParamsHOLSent;
    }

    if (this.sortKeysHOLSent) params.sort_key = this.sortKeysHOLSent;

    if (this.pageSizeHOLSent) {
      params['rows'] = this.pageSizeHOLSent;
    }
    if (this.previousHOLSent) {
      params['previous'] = this.previousHOLSent;
    }
    this.spinnerService.show();
    this.handOverLogService
      .getHandOverLogs(body, params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.totalRowsHOLSent = response['total_size'];

          this.totalPagesHOLSent = Math.ceil(
            this.totalRowsHOLSent / this.pageSizeHOLSent
          );
          this.spinnerService.hide();
          this.otherParamsHOLSent = {
            paginationData: {
              totalRows: this.totalRowsHOLSent,
              previousRows: this.previousHOLSent,
              rows: this.pageSizeHOLSent,
              pageNum: this.pageNumHOLSent,
              totalPages: this.totalPagesHOLSent,
            },
          };
          this.sentHOLDataList = response['data'];
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  onChangePaginationHOLSent(data: any) {
    this.previousHOLSent = data?.paginationData?.previousRows;
    this.pageNumHOLSent = data?.paginationData?.pageNum;
    this.pageSizeHOLSent = data?.paginationData?.rows;

    this.getHOLSentData();

    this.otherParamsHOLSent.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnHOLSent(sortData: any) {
    this.reseHOLSentVariables();
    this.sortKeysHOLSent = sortData?.sortList || [];
    this.getHOLSentData();
  }

  searchColumnHOLSent(searchData: any) {
    this.reseHOLSentVariables();
    this.dateRangeHOLSent = null;
    this.searchParamsHOLSent = searchData?.searchParams || {};

    this.getHOLSentData();
  }
  reseHOLSentVariables() {
    this.totalRowsHOLSent = 0;
    this.previousHOLSent = 0;
    this.pageSizeHOLSent = 10;
    this.pageNumHOLSent = 1;
    this.totalPagesHOLSent = 0;
    this.otherParamsHOLSent.paginationData = {
      totalRows: this.totalRowsHOLSent,
      previousRows: this.previousHOLSent,
      rows: this.pageSizeHOLSent,
      pageNum: this.pageNumHOLSent,
      totalPages: this.totalPagesHOLSent,
    };
  }

  getHOLReceivedData() {
    let params: any = { received: 1 };

    let body: any = {};
    if (this.dateRangeHOLReceived) body.date_range = this.dateRangeHOLReceived;
    if (this.searchParamsHOLReceived) {
      body.ui_filters = this.searchParamsHOLReceived;
    }

    if (this.sortKeysHOLReceived) params.sort_key = this.sortKeysHOLReceived;

    if (this.pageSizeHOLReceived) {
      params['rows'] = this.pageSizeHOLReceived;
    }
    if (this.previousHOLReceived) {
      params['previous'] = this.previousHOLReceived;
    }

    this.spinnerService.show();
    this.handOverLogService
      .getHandOverLogs(body, params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.totalRowsHOLReceived = response['total_size'];

          this.totalPagesHOLReceived = Math.ceil(
            this.totalRowsHOLReceived / this.pageSizeHOLReceived
          );
          this.spinnerService.hide();
          this.otherParamsHOLReceived = {
            paginationData: {
              totalRows: this.totalRowsHOLReceived,
              previousRows: this.previousHOLReceived,
              rows: this.pageSizeHOLReceived,
              pageNum: this.pageNumHOLReceived,
              totalPages: this.totalPagesHOLReceived,
            },
          };
          this.receivedHOLDataList = response['data'];
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  onChangePaginationHOLReceived(data: any) {
    this.previousHOLReceived = data?.paginationData?.previousRows;
    this.pageNumHOLReceived = data?.paginationData?.pageNum;
    this.pageSizeHOLReceived = data?.paginationData?.rows;

    this.getHOLReceivedData();

    this.otherParamsHOLReceived.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnHOLReceived(sortData: any) {
    this.reseHOLReceivedVariables();
    this.sortKeysHOLReceived = sortData?.sortList || [];
    this.getHOLReceivedData();
  }

  searchColumnHOLReceived(searchData: any) {
    this.reseHOLReceivedVariables();
    this.dateRangeHOLReceived = null;
    this.searchParamsHOLReceived = searchData?.searchParams || {};

    this.getHOLReceivedData();
  }
  reseHOLReceivedVariables() {
    this.totalRowsHOLReceived = 0;
    this.previousHOLReceived = 0;
    this.pageSizeHOLReceived = 10;
    this.pageNumHOLReceived = 1;
    this.totalPagesHOLReceived = 0;
    this.otherParamsHOLReceived.paginationData = {
      totalRows: this.totalRowsHOLReceived,
      previousRows: this.previousHOLReceived,
      rows: this.pageSizeHOLReceived,
      pageNum: this.pageNumHOLReceived,
      totalPages: this.totalPagesHOLReceived,
    };
  }

  getHOLReceivedAndSentData() {
    let params: any = {};
    let body: any = {};
    if (this.dateRangeHOLReceivedAndSent)
      body.date_range = this.dateRangeHOLReceivedAndSent;
    if (this.searchParamsHOLReceivedAndSent) {
      body.ui_filters = this.searchParamsHOLReceivedAndSent;
    }

    if (this.sortKeysHOLReceivedAndSent)
      params.sort_key = this.sortKeysHOLReceivedAndSent;

    if (this.pageSizeHOLReceivedAndSent) {
      params['rows'] = this.pageSizeHOLReceivedAndSent;
    }
    if (this.previousHOLReceivedAndSent) {
      params['previous'] = this.previousHOLReceivedAndSent;
    }
    this.spinnerService.show();
    this.handOverLogService
      .getHandOverLogs(body, params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.totalRowsHOLReceivedAndSent = response['total_size'];

          this.totalPagesHOLReceivedAndSent = Math.ceil(
            this.totalRowsHOLReceivedAndSent / this.pageSizeHOLReceivedAndSent
          );
          this.spinnerService.hide();
          this.otherParamsHOLReceivedAndSent = {
            paginationData: {
              totalRows: this.totalRowsHOLReceivedAndSent,
              previousRows: this.previousHOLReceivedAndSent,
              rows: this.pageSizeHOLReceivedAndSent,
              pageNum: this.pageNumHOLReceivedAndSent,
              totalPages: this.totalPagesHOLReceivedAndSent,
            },
          };
          this.receivedAndSentHOLDataList = response['data'];
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  onChangePaginationHOLReceivedAndSent(data: any) {
    this.previousHOLReceivedAndSent = data?.paginationData?.previousRows;
    this.pageNumHOLReceivedAndSent = data?.paginationData?.pageNum;
    this.pageSizeHOLReceivedAndSent = data?.paginationData?.rows;

    this.getHOLReceivedAndSentData();

    this.otherParamsHOLReceivedAndSent.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnHOLReceivedAndSent(sortData: any) {
    this.reseHOLReceivedAndSentVariables();
    this.sortKeysHOLReceivedAndSent = sortData?.sortList || [];
    this.getHOLReceivedAndSentData();
  }

  searchColumnHOLReceivedAndSent(searchData: any) {
    this.reseHOLReceivedAndSentVariables();
    this.dateRangeHOLReceivedAndSent = null;
    this.searchParamsHOLReceivedAndSent = searchData?.searchParams || {};

    this.getHOLReceivedAndSentData();
  }
  reseHOLReceivedAndSentVariables() {
    this.totalRowsHOLReceivedAndSent = 0;
    this.previousHOLReceivedAndSent = 0;
    this.pageSizeHOLReceivedAndSent = 10;
    this.pageNumHOLReceivedAndSent = 1;
    this.totalPagesHOLReceivedAndSent = 0;
    this.otherParamsHOLReceivedAndSent.paginationData = {
      totalRows: this.totalRowsHOLReceivedAndSent,
      previousRows: this.previousHOLReceivedAndSent,
      rows: this.pageSizeHOLReceivedAndSent,
      pageNum: this.pageNumHOLReceivedAndSent,
      totalPages: this.totalPagesHOLReceivedAndSent,
    };
  }
}
