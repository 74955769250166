import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  formatDateTimeStamp,
  getInitalDateRangeForListData,
  getWelfareCheckInterval,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { getDateTimeRange } from '../tableDetails';
import { AddEditUserComponent } from '../users/add-edit-user/add-edit-user.component';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-missed-events',
  templateUrl: './missed-events.component.html',
  styleUrl: './missed-events.component.scss',
})
export class MissedEventsComponent {
  //missed welfare check data variables
  totalRowsMissedWelfareCheck: number = 0;
  previousMissedWelfareCheck: number = 0;
  pageSizeMissedWelfareCheck: number = 10;
  pageNumMissedWelfareCheck: number = 1;
  totalPagesMissedWelfareCheck: number = 0;
  searchParamsMissedWelfareCheck: any;
  sortKeysMissedWelfareCheck: any = ['-missed_at'];
  dateRangeMissedWelfareCheck: any;

  otherParamsMissedWelfareCheck: any = {};
  missedWelfareData: any;
  tableDetailMissedWelfareCheck = [
    {
      header: 'Missed Check-In',
      name: 'missed_at',
      datetimeObj: true,
      dateRangeKey: 'missed_at',
      sortKey: 'missed_at',
      default: true,
      ...getDateTimeRange(3),
    },
    {
      header: 'Guard',
      name: 'full_name',
      searchKey: 'full_name',
      sortKey: 'full_name',
    },
    {
      header: 'Reason',
      name: 'missed_reason',
    },
    {
      header: 'Last Contact',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      ...getDateTimeRange(7),
      tableColumnStyle: {
        'white-space': 'pre-line',
      },
    },
  ];
  mobileDetailMissedWelfareCheck = {
    individualDetails: [
      {
        name: 'full_name',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
          'font-weight': 'bold',
        },
      },
      {
        name: 'missed_at',
        dateRangeKey: 'missed_at',
        ...getDateTimeRange(3),
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
          'margin-top': '0.5rem',
          'font-weight': 'bold',
        },
      },
      {
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': '600',
        },
        nestedValue: (row: any) => {
          return row?.missed_reason;
        },
        prefixStyle: {
          'font-weight': '400',
        },
        prefixNestedValue: (row: any) => {
          return row?.missed_reason ? 'Reason: ' : null;
        },
      },

      {
        prefix: 'Last Contact: ',
        nestedValue: (row: any) => {
          return `${String(
            formatDateTimeStamp(row?.updated_at, 'd MMM y HH:mm', 'en_US')
          )}`;
        },
        header: 'Last Activity',
        dateRangeKey: 'updated_at',
        ...getDateTimeRange(7),
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  // welfare check guard variables

  totalRowsGuardWelfareCheck: number = 0;
  previousGuardWelfareCheck: number = 0;
  pageSizeGuardWelfareCheck: number = 10;
  pageNumGuardWelfareCheck: number = 1;
  totalPagesGuardWelfareCheck: number = 0;
  searchParamsGuardWelfareCheck: any;
  sortKeysGuardWelfareCheck: any;
  guardSelectedInterval: any;

  otherParamsGuardWelfareCheck: any = {};
  guardWelfareCheckData: any;
  tableDetailGuardWelfareCheck = [
    {
      header: 'Name',
      name: 'full_name',
      sortKey: 'full_name',
    },

    {
      header: 'Group',
      name: 'user_group',
      nestedValue: (row: any) => {
        let userGroups = [
          ...row?.['user_group']?.map((item: any) => {
            return item?.name;
          }),
        ].join(', ');

        return userGroups;
      },
      sortKey: 'user_group',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
    {
      header: 'Interval',
      name: 'interval_check',
      sortKey: 'interval_check',
    },
  ];

  dialogeRef: any;

  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();
  welfareReminderDict: any;
  welfareCheckIntervalData: any = [];
  constructor(
    private userService: UsersService,
    private router: Router,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });
    const today = new Date();
    today.setDate(new Date().getDate() - 1);

    this.dateRangeMissedWelfareCheck = getInitalDateRangeForListData(
      this.mobileDetailMissedWelfareCheck?.individualDetails || []
    );

    this.getMissedWelfareCheckData();
    this.getGuardWelfareCheckData();

    [this.welfareCheckIntervalData, this.welfareReminderDict] =
      getWelfareCheckInterval(8 * 60);
  }
  getMissedWelfareCheckData() {
    let body: any = {
      event_type: 'missed_welfare_checks',
    };
    if (this.dateRangeMissedWelfareCheck)
      body.date_range = this.dateRangeMissedWelfareCheck;

    if (this.sortKeysMissedWelfareCheck)
      body.sort_key = this.sortKeysMissedWelfareCheck;

    if (this.searchParamsMissedWelfareCheck)
      body.search_filters = this.searchParamsMissedWelfareCheck;

    if (this.pageSizeMissedWelfareCheck) {
      body['rows'] = this.pageSizeMissedWelfareCheck;
    }
    if (this.previousMissedWelfareCheck) {
      body['previous'] = this.previousMissedWelfareCheck;
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.missedWelfareData = response['data'];

        this.totalRowsMissedWelfareCheck = response['total_size'];

        this.totalPagesMissedWelfareCheck = Math.ceil(
          this.totalRowsMissedWelfareCheck / this.pageSizeMissedWelfareCheck
        );
        this.otherParamsMissedWelfareCheck = {
          paginationData: {
            totalRows: this.totalRowsMissedWelfareCheck,
            previousRows: this.previousMissedWelfareCheck,
            rows: this.pageSizeMissedWelfareCheck,
            pageNum: this.pageNumMissedWelfareCheck,
            totalPages: this.totalPagesMissedWelfareCheck,
          },
        };
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  onChangePaginationMissedWelfareCheck(data: any) {
    this.previousMissedWelfareCheck = data?.paginationData?.previousRows;
    this.pageNumMissedWelfareCheck = data?.paginationData?.pageNum;
    this.pageSizeMissedWelfareCheck = data?.paginationData?.rows;

    this.getMissedWelfareCheckData();

    this.otherParamsMissedWelfareCheck.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnMissedWelfareCheck(sortData: any) {
    this.resetPagenMissedWelfareCheck();
    this.sortKeysMissedWelfareCheck = sortData?.sortList || [];
    this.getMissedWelfareCheckData();
  }

  searchColumnMissedWelfareCheck(searchData: any) {
    this.resetPagenMissedWelfareCheck();
    this.dateRangeMissedWelfareCheck = null;
    this.searchParamsMissedWelfareCheck = searchData?.searchParams || {};

    this.getMissedWelfareCheckData();
  }
  resetPagenMissedWelfareCheck() {
    this.totalRowsMissedWelfareCheck = 0;
    this.previousMissedWelfareCheck = 0;
    this.pageSizeMissedWelfareCheck = 10;
    this.pageNumMissedWelfareCheck = 1;
    this.totalPagesMissedWelfareCheck = 0;
    this.otherParamsMissedWelfareCheck.paginationData = {
      totalRows: this.totalRowsMissedWelfareCheck,
      previousRows: this.previousMissedWelfareCheck,
      rows: this.pageSizeMissedWelfareCheck,
      pageNum: this.pageNumMissedWelfareCheck,
      totalPages: this.totalPagesMissedWelfareCheck,
    };
  }
  openUserDetailTemplate(eventData: any) {
    if (eventData?.subs_user_id) {
      this.dialogService.open(AddEditUserComponent, {
        data: {
          editUser: true,
          detailData: { id: eventData.subs_user_id },
        },
      });
    }
  }

  getGuardWelfareCheckData() {
    let params: any = {};

    if (this.sortKeysGuardWelfareCheck)
      params.sort_key = this.sortKeysGuardWelfareCheck;

    if (this.searchParamsGuardWelfareCheck)
      Object.entries(this.searchParamsGuardWelfareCheck).forEach(
        ([key, value]) => (params[key] = value)
      );

    if (this.pageSizeGuardWelfareCheck) {
      params['rows'] = this.pageSizeGuardWelfareCheck;
    }
    if (this.previousGuardWelfareCheck) {
      params['previous'] = this.previousGuardWelfareCheck;
    }

    this.userService.getUserData(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalRowsGuardWelfareCheck = response['total_size'];

        this.totalPagesGuardWelfareCheck = Math.ceil(
          this.totalRowsGuardWelfareCheck / this.pageSizeGuardWelfareCheck
        );
        this.otherParamsGuardWelfareCheck = {
          paginationData: {
            totalRows: this.totalRowsGuardWelfareCheck,
            previousRows: this.previousGuardWelfareCheck,
            rows: this.pageSizeGuardWelfareCheck,
            pageNum: this.pageNumGuardWelfareCheck,
            totalPages: this.totalPagesGuardWelfareCheck,
          },
        };
        this.guardWelfareCheckData = response['data'];
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  onChangePaginationGuardWelfareCheck(data: any) {
    this.previousGuardWelfareCheck = data?.paginationData?.previousRows;
    this.pageNumGuardWelfareCheck = data?.paginationData?.pageNum;
    this.pageSizeGuardWelfareCheck = data?.paginationData?.rows;

    this.getGuardWelfareCheckData();

    this.otherParamsGuardWelfareCheck.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnGuardWelfareCheck(sortData: any) {
    this.resetPagenGuardWelfareCheck();
    this.sortKeysGuardWelfareCheck = sortData?.sortList || [];
    this.getGuardWelfareCheckData();
  }

  searchColumnGuardWelfareCheck(searchData: any) {
    this.resetPagenGuardWelfareCheck();

    this.searchParamsGuardWelfareCheck = searchData?.searchParams || {};

    this.getGuardWelfareCheckData();
  }
  resetPagenGuardWelfareCheck() {
    this.totalRowsGuardWelfareCheck = 0;
    this.previousGuardWelfareCheck = 0;
    this.pageSizeGuardWelfareCheck = 10;
    this.pageNumGuardWelfareCheck = 1;
    this.totalPagesGuardWelfareCheck = 0;
    this.otherParamsGuardWelfareCheck.paginationData = {
      totalRows: this.totalRowsGuardWelfareCheck,
      previousRows: this.previousGuardWelfareCheck,
      rows: this.pageSizeGuardWelfareCheck,
      pageNum: this.pageNumGuardWelfareCheck,
      totalPages: this.totalPagesGuardWelfareCheck,
    };
  }
  openWelfareCheckTemplate(event: any, template: any) {
    this.guardSelectedInterval =
      event?.interval_check || this.welfareCheckIntervalData[0]?.value;

    this.dialogService.open(template, { data: event }, this.viewContainerRef);
  }
  updateInterval(data: any, interval: any) {
    this.userService
      .updateUser(
        {
          id: data.id,
          interval_check: interval,
        },
        { change_welfare_check: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.getGuardWelfareCheckData();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
}
