<div>
  <!-- Iterate over each card -->
  <div [ngClass]="!largeView ? '' : 'd-flex flex-wrap flex-row gy-3'">
    <!-- Add New Card Button -->
    <div *ngFor="let savedCard of savedCards" class="mb-3">
      <div
        class="gt-payment-card m-auto cursor-pointer"
        [ngClass]="[
          largeView ? 'me-3' : '',
          savedCard === selectedCard ? 'selected-card' : ''
        ]"
      >
        <div
          class="card__front card__part"
          [ngClass]="savedCard?.is_primary ? '' : 'card__inactive'"
          (click)="changeCardStatus(savedCard)"
        >
          <div class="row mb-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <span
                class="badge d-flex align-items-center"
                [ngClass]="savedCard?.is_primary ? 'bg-success' : ''"
              >
                <i
                  *ngIf="savedCard?.is_primary"
                  class="fa-solid fa-check me-2"
                ></i>
                {{ savedCard?.is_primary ? "PRIMARY" : "" }}
              </span>
              <span class="fw-bold">{{ savedCard.brand }}</span>
            </div>
          </div>

          <div class="card_number">{{ savedCard.card_num }}</div>
          <div class="card__space-75">
            <span class="card__label">&nbsp;</span>
            <div class="card__info">{{ savedCard.holder_name }}</div>
          </div>
          <div class="card__space-25">
            <span class="card__label">Expiry</span>
            <div class="card__info">{{ savedCard.expiry }}</div>
          </div>
        </div>
        <div class="delete-icon" (click)="deleteCard(savedCard)">
          <button class="btn btn-sm btn-danger border-0 opacity-100">
            Delete
          </button>
        </div>
      </div>
    </div>

    <div
      class="card__btn-new cursor-pointer gt-payment-card mb-5 border"
      [ngClass]="largeView ? '' : 'm-auto'"
      (click)="addNewCard(addNewPaymentCardTemplateRef)"
    >
      + Add Card
    </div>
  </div>
</div>

<ng-template
  #addNewPaymentCardTemplateRef
  let-addNewPaymentCardTemplateRef="dialogRef"
>
  <div class="card dialog-box" data-bs-theme="light">
    <div class="card-header">
      Add Payment Card
      <button
        type="button"
        class="btn-close close-icon float-end"
        aria-label="Close"
        (click)="addNewPaymentCardTemplateRef.close()"
      ></button>
    </div>
    <ng-container *ngIf="showSqForm">
      <input
        class="form-control mt-3 lh-lg mb-2"
        type="text"
        [(ngModel)]="cardHolderName"
        id="name"
        placeholder="Name"
      />
      <div id="card-container"></div>

      <button
        type="button"
        class="btn btn-primary btn-lg w-100 mb-2"
        [disabled]="!cardHolderName"
        (click)="tokenizeAddNewCard()"
      >
        Add Card
      </button>
      <div>
        Please note that we do not save your credit card details. These details
        are saved securely via our payment partner
        <a
          *ngIf="!isPWAApp"
          href="https://squareup.com/au/en/legal/general/ua"
          target="_"
          >SquareUp</a
        >
        <span *ngIf="isPWAApp">SquareUp</span>.
      </div>
    </ng-container>
  </div>
</ng-template>
