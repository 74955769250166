import { Component, OnInit } from '@angular/core';
import { cardDeleteActionIcon } from '../../../../global.variable';
import { AddEditResponseTypeComponent } from '../../../shared/components/add-edit-response-type/add-edit-response-type.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ClientsService } from '../clients/clients.service';

@Component({
  selector: 'gtapp-response-type',
  templateUrl: './response-type.component.html',
  styleUrl: './response-type.component.scss',
})
export class ResponseTypeComponent implements OnInit {
  cardDetails = {
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: {
          'font-weight': 'bolder',
          'padding': '1rem',
          'display': 'flex',
        },
      },
    ],
  };

  responseTypeList: any = [];
  initialList: any = [];

  dialogref: any;
  cardActionIcons = cardDeleteActionIcon;

  responseTypeDropDownList: any = [];
  constructor(
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.fetchResponseTypeList();
  }

  fetchResponseTypeList() {
    let params: any = {};

    this.spinnerService.show();
    this.clientService.fetchResponseTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.responseTypeList = res?.data;
        this.initialList = res?.data;
      }
      this.spinnerService.hide();
    });
  }

  onResponseTypeLookup(event: any) {
    if (event.target.value.length) {
      this.responseTypeDropDownList = this.initialList?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    }
  }
  filterResults(event: any) {
    if (event) {
      this.responseTypeList = this.initialList?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event?.toLowerCase())
      );
    } else {
      this.responseTypeList = this.initialList;
    }
  }

  openAddResponseForm() {
    this.dialogref = this.dialogService.open(AddEditResponseTypeComponent, {
      data: { updateView: false },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.fetchResponseTypeList();
      }
    });
  }
  openEditResponseForm(data: any) {
    this.dialogref = this.dialogService.open(AddEditResponseTypeComponent, {
      data: { updateView: true, responseData: data },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.fetchResponseTypeList();
      }
    });
  }
  deleteResponse(data: any) {
    let dialogMsg = 'WARNING: This action cannot be reversed.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Response Type',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .deleteResponseType(data.id, { delete: 1 })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.fetchResponseTypeList();
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  onCloseDialogue() {
    this.dialogref.close();
  }
}
