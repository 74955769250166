import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[gtappCustomTooltip]',
})
export class CustomTooltipDirective implements OnChanges {
  constructor(
    @Inject(ElementRef) private el: ElementRef,
    @Inject(Renderer2) private renderer: Renderer2
  ) {}
  @Input() gtappCustomTooltip: string | null = null;
  ngOnChanges(): void {
    if (this.gtappCustomTooltip === 'danger') {
      this.applyCustomTooltip();
    }
  }
  applyCustomTooltip() {
    setTimeout(() => {
      const element = this.el.nativeElement;

      element.addEventListener('shown.bs.tooltip', () => {
        const tooltipId = element.getAttribute('aria-describedby');

        if (tooltipId) {
          const tooltipElement = document.getElementById(tooltipId);
          if (tooltipElement) {
            this.applyCustomStyles(tooltipElement);
          }
        }
      });
    }, 1);
  }
  private applyCustomStyles(tooltipElement: HTMLElement) {
    // Applying custom styles directly

    const tooltipInnerElement = tooltipElement.querySelector('.tooltip-inner');
    const styles: any = {
      'background-color': 'var(--bs-red)',
      'color': '#ffffff',
      'white-space': 'pre-line',
      'font-weight': '600',
      'font-family': 'Poppins',
      'border': '1px solid black',
    };

    Object.keys(styles).forEach((style) => {
      this.renderer.setStyle(tooltipInnerElement, style, styles[style]);
    });
    const tooltipArrow = tooltipElement.querySelector('.tooltip-arrow');

    if (tooltipArrow) {
      this.renderer.addClass(tooltipArrow, 'custom-tooltip-arrow');
    }
  }
}
