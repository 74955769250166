import { Component } from '@angular/core';
import { cardDeleteActionIcon } from '../../../../global.variable';
import { AddEditIncidentTypeComponent } from '../../../shared/components/add-edit-incident-type/add-edit-incident-type.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { IncidentsService } from '../incidents/incidents.service';

@Component({
  selector: 'gtapp-incident-type',
  templateUrl: './incident-type.component.html',
  styleUrl: './incident-type.component.scss',
})
export class IncidentTypeComponent {
  cardDetails = {
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: {
          'font-weight': 'bolder',
          'padding': '1rem',
          'display': 'flex',
        },
      },
    ],
  };

  incidentTypeList: any = [];
  initialList: any = [];

  dialogref: any;
  cardActionIcons = cardDeleteActionIcon;

  incidentTypeDropDownList: any = [];
  constructor(
    private incidentService: IncidentsService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.fetchIncidentTypeList();
  }
  filterResults(event: any) {
    if (event) {
      this.incidentTypeList = this.initialList?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event?.toLowerCase())
      );
    } else {
      this.incidentTypeList = this.initialList;
    }
  }

  fetchIncidentTypeList() {
    let params: any = {};

    this.spinnerService.show();
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeList = res?.data;
        this.initialList = res?.data;
      }
      this.spinnerService.hide();
    });
  }
  onIncidentTypeLookup(event: any) {
    if (event.target.value.length) {
      this.incidentTypeDropDownList = this.initialList?.filter((type: any) =>
        type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    }
  }

  openAddEditIncidentTypeComponent(data?: any) {
    this.dialogref = this.dialogService.open(AddEditIncidentTypeComponent, {
      data: { incidentTypeData: data },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.fetchIncidentTypeList();
      }
    });
  }
  deleteIncidentType(data: any) {
    let dialogMsg = 'WARNING: This action cannot be reversed.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Incident Type',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.incidentService
          .deleteIncidentType(data.id, { delete: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.fetchIncidentTypeList();
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  onCloseDialogue() {
    this.dialogref.close();
  }
}
