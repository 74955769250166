<div class="card-body">
  <div class="row">
    <div class="d-flex flex-column flex-md-row input-btn-group">
      <div class="flex-md-grow-1 mb-2 mb-md-0">
        <gtapp-auto-complete
          [data]="incidentDropDownList"
          placeHolder="Search Incidents"
          searchKeyword="name"
          [initialValue]="globalFilter"
          [itemTemplate]="incidentLookupTemplate"
          (inputChanged)="onIncidentLookup($event)"
          (selected)="onSelectIncident($event)"
          (submitted)="globalFilter = $event; getIncidents()"
          [enableSearchCloseOption]="true"
          (fieldCleared)="globalFilter = null; getIncidents()"
        >
        </gtapp-auto-complete>

        <ng-template #incidentLookupTemplate let-item>
          <div>
            {{ item.incident_key }}
          </div>
          <div>{{ item?.site_name }} [{{ item?.company_name }}]</div>
        </ng-template>
      </div>
      <div class="ms-md-2 mb-2 mb-lg-0">
        <button class="btn btn-primary btn-lg w-100" (click)="addIncident()">
          + Open Incident
        </button>
      </div>
      <div class="ms-md-2 mb-2 mb-lg-0">
        <button
          *ngIf="isAdmin || isDispatchUser === true"
          class="btn btn-lg w-100"
          (click)="showAllIncidents()"
          [ngClass]="defaultIncidents ? 'btn-secondary' : 'btn-basic'"
        >
          {{ defaultIncidents ? "Show All" : "Reset" }}
        </button>
      </div>
    </div>
  </div>

  @if(largeView){
  <ng-container>
    <!-- Table View for Desktop -->
    <gtapp-gt-table
      [desktopTableBackendData]="incidentList"
      [desktopTableDetails]="incidentListTableDetails"
      [tableStyle]="tableStyle"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (searchColumn)="searchColumn($event)"
      (rowClick)="onSelectIncident($event)"
      (paginationChange)="onChangePagination($event)"
    >
    </gtapp-gt-table>
  </ng-container>

  }@else{
  <ng-container>
    <!-- Card List for Mobile View -->
    <gtapp-card-list
      [mobileCardBackendData]="incidentList"
      [mobileCardDetails]="incidentCardDetails"
      (rowClick)="onSelectIncident($event)"
      [otherParams]="otherParams"
      (sortColumn)="sortColumn($event)"
      (rowClick)="onSelectIncident($event)"
      (paginationChange)="onChangePagination($event)"
    ></gtapp-card-list>
  </ng-container>
  }
</div>
