<div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <a
        href="#missed-welfare"
        class="nav-link active"
        id="missed-welfare-tab"
        data-bs-toggle="tab"
        role="tab"
        aria-selected="true"
      >
        Missed Welfare Checks
      </a>
    </li>
    <li class="nav-item" role="presentation">
      <a
        href="#guard-welfare"
        class="nav-link"
        id="guard-welfare-tab"
        data-bs-toggle="tab"
        role="tab"
        aria-selected="true"
      >
        Guards
      </a>
    </li>
  </ul>
</div>

<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="missed-welfare"
    role="tabpanel"
    aria-labelledby="missed-welfare-tab"
  >
    <div class="mt-2">
      @if(largeView){

      <gtapp-gt-table
        [desktopTableBackendData]="missedWelfareData"
        [desktopTableDetails]="tableDetailMissedWelfareCheck"
        [otherParams]="otherParamsMissedWelfareCheck"
        (searchColumn)="searchColumnMissedWelfareCheck($event)"
        (sortColumn)="sortColumnMissedWelfareCheck($event)"
        (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
        (rowClick)="openUserDetailTemplate($event)"
      >
      </gtapp-gt-table>

      }@else {
      <gtapp-card-list
        [mobileCardBackendData]="missedWelfareData"
        [mobileCardDetails]="mobileDetailMissedWelfareCheck"
        [otherParams]="otherParamsMissedWelfareCheck"
        (searchColumn)="searchColumnMissedWelfareCheck($event)"
        (sortColumn)="sortColumnMissedWelfareCheck($event)"
        (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
        (rowClick)="openUserDetailTemplate($event)"
      ></gtapp-card-list>
      }
    </div>
  </div>
  <div
    class="tab-pane fade"
    id="guard-welfare"
    role="tabpanel"
    aria-labelledby="guard-welfare-tab"
  >
    <div class="alert alert-primary text-center recommendation-alert my-4">
      Welfare Checks can also be configured for rostered shifts.
    </div>
    <div>
      <gtapp-gt-table
        [desktopTableBackendData]="guardWelfareCheckData"
        [desktopTableDetails]="tableDetailGuardWelfareCheck"
        [otherParams]="otherParamsGuardWelfareCheck"
        (searchColumn)="searchColumnGuardWelfareCheck($event)"
        (sortColumn)="sortColumnGuardWelfareCheck($event)"
        (paginationChange)="onChangePaginationGuardWelfareCheck($event)"
        (rowClick)="openWelfareCheckTemplate($event, welfareIntervalTemplate)"
      >
      </gtapp-gt-table>
    </div>
  </div>
</div>

<ng-template
  #welfareIntervalTemplate
  let-welfareIntervalTemplate="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Welfare Check Interval
      <span class="float-end" (click)="welfareIntervalTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="form-floating">
      <select
        class="form-select"
        id="welfareChecks"
        [(ngModel)]="guardSelectedInterval"
      >
        <option
          *ngFor="let interval of welfareCheckIntervalData"
          [value]="interval.value"
        >
          {{ interval.name }}
        </option>
      </select>
      <label for="">Welfare Check Interval</label>
    </div>
    <div *ngIf="guardSelectedInterval > 0" class="alert">
      <div class="mb-2">
        Guards will be reminded to submit a welfare check
        <span class="fw-bold">
          {{ welfareReminderDict?.[guardSelectedInterval]  }} minutes
          before</span
        >
        the interval set above.
      </div>
      <div>
        Maximum inactivity time allowed before a missed welfare check is
        recorded:
        <span class="fst-italic fw-bold">
          {{ guardSelectedInterval }} minutes.
        </span>
      </div>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="welfareIntervalTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          [disabled]="guardSelectedInterval == data?.interval_check"
          (click)="
            updateInterval(data, guardSelectedInterval);
            welfareIntervalTemplate.close()
          "
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>
