<div class="pt-2">
  <ng-container *ngIf="groupUserCount > 1">
    <span class="float-end">
      <button
        class="btn btn-sm w-100"
        [ngClass]="holDraftCount > 0 ? 'btn-success' : 'btn-primary'"
        [routerLink]="['/add-hand-over-log']"
      >
        {{ holDraftCount > 0 ? "See Draft" : "+ New" }}
      </button>
    </span>
  </ng-container>

  <div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        class="nav-item"
        *ngFor="let tab of tabNames | keyvalue"
        (click)="changeTab(tab.value)"
      >
        <a
          class="nav-link"
          [ngClass]="selectedTab === tab.value ? 'active' : ''"
          >{{ tab.value }}</a
        >
      </li>
    </ul>
  </div>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab1 ? 'active' : ''"
    >
      <div>
        <gtapp-gt-table
          [desktopTableBackendData]="receivedHOLDataList"
          [desktopTableDetails]="tableDetailHOLReceived"
          [otherParams]="otherParamsHOLReceived"
          (searchColumn)="searchColumnHOLReceived($event)"
          (sortColumn)="sortColumnHOLReceived($event)"
          (paginationChange)="onChangePaginationHOLReceived($event)"
          (rowClick)="onRowClick($event)"
        >
        </gtapp-gt-table>
      </div>
    </div>
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab2 ? 'active' : ''"
    >
      <div>
        <gtapp-gt-table
          [desktopTableBackendData]="sentHOLDataList"
          [desktopTableDetails]="tableDetailHOLSent"
          [otherParams]="otherParamsHOLSent"
          (searchColumn)="searchColumnHOLSent($event)"
          (sortColumn)="sortColumnHOLSent($event)"
          (paginationChange)="onChangePaginationHOLSent($event)"
          (rowClick)="onRowClick($event)"
        >
        </gtapp-gt-table>
      </div>
    </div>
    <div
      class="tab-pane"
      [ngClass]="selectedTab === tabNames.tab3 ? 'active' : ''"
    >
      <div>
        <gtapp-gt-table
          [desktopTableBackendData]="receivedAndSentHOLDataList"
          [desktopTableDetails]="tableDetailHOLReceivedAndSent"
          [otherParams]="otherParamsHOLReceivedAndSent"
          (searchColumn)="searchColumnHOLReceivedAndSent($event)"
          (sortColumn)="sortColumnHOLReceivedAndSent($event)"
          (paginationChange)="onChangePaginationHOLReceivedAndSent($event)"
          (rowClick)="onRowClick($event)"
        >
        </gtapp-gt-table>
      </div>
    </div>
  </div>
</div>
