<div class="mb-4 fw-bold">Log Incident</div>
<div class="row">
  <div class="col-12 col-md-6">
    <div *ngIf="incidentDict.site.data?.id" class="mb-2">
      <dl>
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <dt>Site</dt>
            <dd>
              {{ incidentDict.site.value }}
              <div>[{{ incidentDict.client.value }}]</div>
            </dd>
          </div>
          <div>
            <span (click)="editField('site')">
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i
            ></span>
          </div>
        </div>
      </dl>
    </div>
    <div class="mb-3" *ngIf="!incidentDict.site.data?.id">
      <gtapp-auto-complete
        [data]="siteList"
        placeHolder="Select Site"
        searchKeyword="company_name"
        [initialValue]="incidentDict.site.tempValue"
        [itemTemplate]="siteTemplate"
        [addInitialKey]="isAdmin || isDispatchUser"
        (inputChanged)="onSiteSearch($event)"
        (selected)="onSelectSite($event)"
        inputId="siteId"
        [enableCloseOption]="true"
        (fieldCleared)="editField('site')"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #siteTemplate let-item>
      @if (item?.key === 0) {
      <div>Add New Site</div>
      } @else {
      <div>
        <div class="fw-medium">
          {{ item?.company_name }} [{{ item?.company?.company_name }}]
        </div>

        <div class="small-font text-secondary">
          {{ item?.address?.full_address }}
        </div>
      </div>
      }
    </ng-template>

    <ng-container *ngIf="incidentDict.site.data?.id">
      <div *ngIf="incidentDict.incidentType.data?.id" class="mb-2">
        <dl>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <dt>Incident Type</dt>
              <dd>
                {{ incidentDict.incidentType.value }}
              </dd>
            </div>
            <div>
              <span (click)="editField('it')">
                <i
                  class="fa-solid fa-pen-to-square md-font-size text-secondary"
                ></i
              ></span>
            </div>
          </div>
        </dl>
      </div>
      <div class="mb-3" *ngIf="!incidentDict.incidentType.data?.id">
        <gtapp-auto-complete
          [data]="incidentTypeList"
          placeHolder="Incident Type"
          searchKeyword="name"
          [initialValue]="incidentDict.incidentType.tempValue"
          [itemTemplate]="incidentTypeTemplate"
          [addInitialKey]="incidentDict.incidentType.tempValue"
          (inputChanged)="onSearchIncidentType($event)"
          (selected)="onSelectIncidentType($event)"
          inputId="iTypeLookupField"
          [enableCloseOption]="true"
          (fieldCleared)="editField('it')"
        >
        </gtapp-auto-complete>
      </div>

      <ng-template #incidentTypeTemplate let-item>
        @if (item?.key === 0) {
        <div>Add Incident Type</div>
        } @else {

        <div>{{ item?.name }}</div>

        }
      </ng-template>
      <div>
        <div class="col-md-12">
          <div class="form-floating mb-3">
            <input
              type="datetime-local"
              class="form-control"
              placeholder="Incident Took Place"
              [(ngModel)]="incidentDict.iDay.value"
              (change)="formateStartDate($event)"
            />
            <label>Incident Took Place</label>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row row-cols-sm-2">
      <div class="col">
        <button class="btn btn-dark btn-lg w-100" [routerLink]="['/incidents']">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          [disabled]="
            !(
              incidentDict.site.data &&
              !addIncidentButtonPressed &&
              incidentDict.incidentType.data?.id
            )
          "
          (click)="onAddIncident()"
        >
          Log Incident
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      incidentDict.site?.data?.address?.latitude &&
      incidentDict.site?.data?.address?.longitude
    "
    class="col-6 d-none d-md-block"
  >
    <gtapp-map-leaflet
      [latLon]="{
        lat: incidentDict.site?.data?.address?.latitude,
        lon: incidentDict.site?.data?.address?.longitude
      }"
      mapId="addIncidentMap"
    ></gtapp-map-leaflet>
  </div>
</div>
